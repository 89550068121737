@import "../../../sass/mixins";

.servicesAdvice {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #1900A8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include respond(s) {
    height: auto;
    padding: 10rem 2rem;
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: .5;
    &__content {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    @include respond(s) {
      flex-direction: column;
    }
  }

  &__container {
    width: 35%;
    @include respond(s) {
      width: 100%;
    }
  }

  &__caps {
    position: relative;
    &::before {
      content: " ";
      display: block;
      background-image: url(../../../img/s2_floating_forms.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 40rem;
      height: 15rem;
      position: absolute;
      top: -10rem;
      left: -10rem;
      @include respond(s) {
        display: none;
      }
      
    }

    &::after {
      content: " ";
      display: block;
      background-image: url(../../../img/s2_floating_icon.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 10rem;
      height: 10rem;
      position: absolute;
      top: -10rem;
      right: -5rem;
      @include respond(s) {
        display: none;
      }
    }
  }

  &__icon {
    display: none;
    @include respond(s) {
      display: block;
      width: 10rem;
      height: 9rem;
    }
  }

  
}