@import "sass/normalize";
@import "sass/abstracts";
@import "sass/mixins";
@import "sass/base";
@import "sass/typography";


.Header {
    position: fixed;
    z-index: 10;
    width: 100%;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
