.heading-sec {
    font-size: 7.1rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: -6px;
    text-align: right;
    line-height: 7rem;
    &--italic {
      display: block;
      font-family: "Dancing Script";
      color: #75EEE6;
      font-size: 11.7rem;
      @include respond(s) {
        font-size: 5.4rem;
        letter-spacing: -1px;
      }
    }
    @include respond(s) {
      font-size: 3.6rem;
      line-height: 5.5rem;
      letter-spacing: -1px;
    }
}

.heading-third {
  font-size: 6.5rem;
  color: #fff;
  font-weight: bold;
  letter-spacing: -2px;
  text-align: center;
  line-height: 7rem;
  text-transform: uppercase;
  &::before {
    content: "::: ";
    letter-spacing: 0;
  }
  &::after {
    content: " :::";
    letter-spacing: 0;
  }

  @include respond(s) {
    font-size: 3.5rem;
    letter-spacing: -1px;
  }
}

.heading-fourth {
  font-size: 2.4rem;
  color: #fff;
  font-weight: 500;
  letter-spacing: -2px;
  text-align: left;
  line-height: 7rem;
}

p, .paragraph {
  font-size: 2.8rem;
  color: #fff;
  text-align: right;
  line-height: 3rem;
  &--green {
    color: #5CEDF5;
  }
}