
// COLORS

$primary-color: #100037;

//Colors
$color-primary:#6678d5;
$color-primary-light: #a9b3e7;
$color-primary-dark: #23327f;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-gray-light-1: #f7f7f7;
$color-gray-light-2: #eee;


$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-gray-dark-3: #333;


$color-white: #fff;
$color-black: #000;



//FONT
$default-font-size: 1.6rem;

//Grid

$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;

$gutter-horizontal:6rem;

//FONT
$default-font-size: 1.6rem;