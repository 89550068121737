@import "../../../sass/mixins";
.Contact {
  background-color: #100037;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 20rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @include respond(s) {
    height: auto;
    flex-direction: column;
    padding: 3rem 5rem;
  }
  
  &__form {
    width: 30%;
    margin-right: 5rem;
    @include respond(s) {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
  &__formElement {
    width: 100%;
    margin-bottom: 1rem;
    label {
      display: none;
    }
    input, textarea {
      background-color: transparent;
      border: 1px solid #556fb3;
      color: #a7b5d8;
    }
  }
  
  &__formSubmit {
    font-size: 1.6rem;
    border-radius: 4px;
    border: double 4px transparent;
    width: 70%;
    height: 4rem;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    margin-top: 1rem;
    background-image: linear-gradient(
90deg
, #3F296B 0%, #573994 70%, #3F296B 100%), radial-gradient(circle at top left, #00E9D6, #1CA1E3, #8D58EE);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  &__info {
    width: 20rem;
    @include respond(s) {
      width: 100%;
    }
  }

  &__infoText {
    font-size: 2.8rem;
    color: #6a7bab;
    &--bold {
      font-weight: 700;
    }
  }

  .mediaList {
    list-style: none;
    color: #00edd5;
    font-size: 1.6rem;
    ion-icon {
      margin-right: 1rem;
    }
    &__text {
      color: #fff;
    }
  }
}