@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width:36em){ @content }; //576px
  }

  @if $breakpoint == s {
    @media (max-width:48em){ @content }; //768px
  }

  @if $breakpoint == m {
    @media (max-width:64em){ @content }; //1024px
  }

  @if $breakpoint == l {
    @media (max-width:75em){ @content }; //1200px; Normal styles apply
  }

  @if $breakpoint == xl {
    @media (min-width:112.5em){ @content }; //1800px
  }

}

$breakpoints: (
  'small': (
    min-width: 375px,
  ),
  'small-medium': (
    min-width: 600px,
  ),
  'medium': (
    min-width: 768px,
  ),
  'large': (
    min-width: 1024px,
  ),
  'extra-large': (
    min-width: 1440px,
  ),
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}
