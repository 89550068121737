@import "../../../sass/abstracts";
@import "../../../sass/mixins";

.Header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3rem;
    background: transparent;
    transition: background 0.3s ease-in-out;
    &__bg {
      background: #100039;
    }

    &__logo {
        width: 20rem;
        margin-inline: auto;
        @include respond-to('large') {
          margin-inline: 0rem 20rem;
        }
    }

    &__img {
        width: 100%;
        display: block;
    }

    &__navbar {
      @include respond(s) {
        display: none;
      }
    }

    .navbar {
        display: flex;
        list-style: none;

        :not(:last-child) {
            margin-right: 2rem;
        }

        &__item {
          display: flex;
          align-items: center;
        }

        &__link {
            &:link,
            &:visited{
                font-size: 1.6rem;
                color: #fff;
                display: flex;
                text-decoration: none;
                padding: 3px;
                transition: all .2s ease-out;
            }

            &:hover {
                text-shadow: 0 1rem 2rem rgba(0,0,0,.15);
                transform: translateY(-2px);    
                color: #5CEDF5;      
            }
          &--image {
            width: 80px;
          }
        }
        
    }
}

.navigation {
  display: none;
  @include respond(s) {
    display: block;
  }
  &__checkbox {
    display: none;
  }
  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    left: 0;
  }

  &__background {
    height: 4rem;
    width: 4rem;
    border-radius: 4px;
    position: fixed;
    top: 2rem;
    right: 3rem;
    background-image: radial-gradient($color-primary-light, $color-primary-dark);
    z-index: 1000;
    transition: transform .8s cubic-bezier(0.83, 0, 0.17, 1);

    // transform: scale(80);
  }

  &__nav {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1500;
    opacity: 0;
    width: 0;

    transition: all .8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    display: inline-block;
    &:link,
    &:visited{
      font-size: 3rem;
      font-weight: 300;
      padding: 1rem 2rem;
      color: $color-white;
      text-decoration: none;
      background-size: 230%;
      transition: all .4s;
    }
    &:hover,
    &:active {
      text-shadow: 0 1rem 2rem rgba(0,0,0,.15);
      transform: translateY(-2px);    
      color: #5CEDF5;      
    }
    &--image {
      width: 13rem;
    }
  }


  &__button {
    background-color: #100039;
    border: 1px solid $color-white;
    height: 4rem;
    width: 4rem;
    border-radius: 4px;
    position: fixed;
    top: 2rem;
    right: 3rem;
    z-index: 2000;
    text-align: center;
    box-shadow: 0 1rem 3rem rgba($color: $color-black, $alpha: .1);
    cursor: pointer;
  }

  //ICON
  &__icon {
    position: relative;
    margin-top: 1.8rem;

    &,
    &::before,
    &::after {
      width: 2.5rem;
      height: 2px;
      background-color: $color-white;
      display: inline-block;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;
    }

    &::before{top: -.8rem; transform-origin: center;}
    &::after{top: .8rem;}
  }

  &__button:hover &__icon::before {top: -1rem;}
  &__button:hover &__icon::after {top: 1rem;}

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  } 

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  } 

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  } 
}