html {
  //This defines what 1rem is
  font-size: 62.5%;   //1rem=10px; 10px/16px=62.5%
  
  @include respond(s) {
    font-size:30% //1rem=4.8px; 8px/16px=30%
  }
  
  @include respond(m) {
    font-size:50% //1rem=8px; 8px/16px=50%
  }

  @include respond(l) {
    font-size:56.25%;    //1rem=9px; 9px/16px=56.25%
  }
  
  @include respond(xl) {
    font-size: 62.5%;   //1rem=10px; 10px/16px=62.5%
  }
  
}

  * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  
  body {
    box-sizing: border-box;
    @include respond(m) {
      padding: 0;
    }
  }

  //Typography 
  body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 1.7;
  }
  

  //Form Components
  input {
    &[type="text"], &[type="email"], &[type="number"]{
      border-radius: 8px;
      height: 3rem;
      width: 100%;
      padding-left: 1rem;
      font-size: 1.6rem;
      outline:none;

      &::-webkit-input-placeholder {
        color: #556fb3;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */    
      }
      &:focus {
        outline:none;
        box-shadow: 0 1rem 2rem rgba(#000, 0.1);
      }  
      &:invalid {
        border: 1px solid #770700;
      }
    }
  }

  textarea {
    border-radius: 8px;
      height: 6rem;
      width: 100%;
      padding-left: 1rem;
      padding-top: 1rem;
      font-size: 1.6rem;
      outline:none;

      &::-webkit-input-placeholder {
        color: #556fb3;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */    
      }
      &:focus {
        outline:none;
        box-shadow: 0 1rem 2rem rgba(#000, 0.1);
      }  
      &:invalid {
        border: 1px solid #770700;
      }
  }

  