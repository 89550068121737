@import "../../../sass/mixins";

.ExperiencesAdvice {
  background-color: #5F55B5;
  background-image: url(../../../img/s3_bg.png), ;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
  @include respond(s) {
    height: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 5rem;

  }
  &::before {
    content: '';
    display: block;
    background-image: url(../../../img/s3_corner_bottom.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &::after {
    content: '';
    display: block;
    background-image: url(../../../img/s3_corner_top.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 0;
    padding-bottom: 20%;
    position: absolute;
    top: 0;
    right: 0;
  }
  &__heading {
    position: absolute;
    width: 50%;
    right: 10%;
    bottom: 50%;
    transform: translateY(50%);
    @include respond(s) {
      position: relative;
      width: auto;
      transform: none;
      right: inherit;
      bottom: inherit;
    }
  }

  &__icon {
    display: none;
    @include respond(s) {
      display: block;
      width: 12rem;
      height: 13rem;
      margin: 0 auto;
      margin-bottom: 3rem;
    }
  }
}