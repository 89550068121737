@import "../../../sass/mixins";

.IntroContainer {
  background: linear-gradient(rgba(#060415, 0.6), rgba(#5022e9, 0.6)),
    url(../../../img/hero-bg-mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  @include respond-to("small-medium") {
    background: none;
    &::after {
      content: "";
      transform: scaleX(-1);
      background: linear-gradient(rgba(#060415, 0.6), rgba(#5022e9, 0.6)),
        url(../../../img/hero-bg.webp);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    align-items: flex-start;
    padding-inline: 140px;
  }
  @include respond-to("extra-large") {
    &::before {
      content: "";
      display: block;
      width: 35vw;
      height: 41.7vh;
      background-color: orangered;
      position: absolute;
      right: 0;
      top: 60%;
      transform: translateY(-50%);
      background: url(../../../img/nodo-hero-texture.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-inline: 24px;
    @include respond-to("small-medium") {
      max-width: 513px;
      padding-inline: 0;
    }
  }
  &__header {
    color: #ffffff;
    font-size: 32px;
    line-height: 36px;
    font-weight: 800;
    text-align: left;
    @include respond-to("small-medium") {
      font-size: 43px;
      line-height: 55px;
    }
    @include respond-to("large") {
      font-size: 55px;
    }
  }
  &__isotype {
    display: none;
    align-items: center;
    justify-content: center;
    @include respond-to("small-medium") {
      display: inline-flex;
    }
  }
  &__separator {
    display: block;
    height: 6px;
    width: 38px;
    background-color: #8676c0;
    margin-block: 36px;
  }
  &__subheader {
    color: #a7fffb;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 35px;
    text-align: left;
    @include respond-to("large") {
      font-size: 22px;
    }
  }
}
